body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "segoe ui", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-body  {
  /* max-width: 60%; */
  padding-top: 45px;
  margin: auto;
}

@media (min-width:600px)  {
  .main-body {
    max-width: 60%;
  }

}